<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" more-btn="高级搜索" :fold-search.sync="showFoldSearch" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="模型编号">
              <el-input v-model="searchForm.modelCode" placeholder="请输入要搜索的模型编号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="模型名称">
              <el-input v-model="searchForm.modelName" placeholder="请输入要搜索的模型名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="上架状态">
              <el-select v-model="searchForm.state" placeholder="请选择要搜索的状态" filterable>
                <el-option label="全部" value="" />
                <el-option v-for="item in constants.modelStatus" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col />
          <el-col v-show="showFoldSearch" :span="6">
            <el-form-item label="创建人">
              <el-input v-model="searchForm.createBy" placeholder="请输入要搜索的创建人" />
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="12">
            <el-form-item label="创建时间">
              <ics-search-date :date.sync="createdArr" />
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
        <template slot="btn-inner">
          <el-button type="primary" @click="$router.push({ name: 'modelEdit', params: { editMode: 'add' } })">
            新增模型
          </el-button>
        </template>
        <template slot="table-columns-before">
          <el-table-column fixed="left" label="序号" type="index" :index="indexMethod" />
        </template>
        <template slot="table-columns-after">
          <el-table-column fixed="right" label="操作" width="160">
            <template v-slot="scope">
              <ics-auth-manage code="金融产品模型">
                <el-link v-if="[0, 3].includes(scope.row.approvalStatus)" type="primary" style="margin-left: 10px;" class="text-btn" @click="dialogAudit(scope.row.id)">
                  审批
                </el-link>
              </ics-auth-manage>
              <el-link v-if="scope.row.state === 0 && [1, 4].includes(scope.row.approvalStatus)" type="primary" class="text-btn" @click="editBeenOnOff(scope.row, 1)">
                上架
              </el-link>
              <el-link v-if="scope.row.state === 1 && [1, 4].includes(scope.row.approvalStatus)" type="danger" class="text-btn" @click="editBeenOnOff(scope.row, 0)">
                下架
              </el-link>
              <el-link v-if="[1, 4, 5].includes(scope.row.approvalStatus) && scope.row.state === 0" type="primary" class="text-btn" @click="$router.push({ name: 'modelEdit', params: { editMode: 'edit' }, query: { id: scope.row.id } })">
                变更
              </el-link>
              <el-link v-if="scope.row.approvalStatus === 2" type="danger" class="text-btn" @click="deleteBusiness(scope.row.id)">
                删除
              </el-link>
              <el-link type="primary" class="text-btn" @click="$router.push({ name: 'modelDetail', query: { id: scope.row.id } })">
                详情
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ics-table-inner>
    </div>
    <ics-audit-inner :loading-submit="loadingSubmit" :dialog="dialog.audit" @submit="submitAudit" />
  </el-main>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import utils from '@/assets/js/utils'
import IcsAuthManage from '@/components/auth-manage'
import IcsAuditInner from '@/components/audit-inner'

export default {
  components: { IcsAuthManage, IcsAuditInner },
  mixins: [basePageListMixin],
  data () {
    return {
      showFoldSearch: false,
      baseSearchForm: {
        modelName: '',
        modelCode: '',
        state: '',
        createBy: '',
        startDate: '',
        endDate: ''
      },
      businessId: '',
      loadingSubmit: {
        submit: false
      },
      dialog: {
        audit: {
          visible: false,
          title: '审批产品模型'
        }
      },
      columnOption: [
        { label: '模型编号', prop: 'modelCode', formatter: this.utils.isEffective, canSet: false, fixed: 'left', minWidth: 100 },
        { label: '模型名称', prop: 'modelName', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建人', prop: 'createdBy', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建时间', prop: 'createdAt', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '状态', prop: 'state', formatter: (row, col, cell) => this.utils.statusFormat(Number(cell), 'modelStatus'), minWidth: 100 },
        { label: '审批状态', prop: 'approvalStatus', formatter: (row, col, cell) => this.utils.statusFormat(Number(cell), 'productAuditStatus'), minWidth: 100 },
      ]
    }
  },
  computed: {
    createdArr: utils.computedDate('startDate', 'endDate')
  },
  methods: {
    getList () {
      this.loading.list = true
      this.api.product.model.pageList(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    },
    dialogAudit (val) {
      this.businessId = val
      this.dialog.audit.visible = true
    },
    submitAudit (formData) {
      return new Promise((resolve, reject) => {
        this.loadingSubmit.submit = true
        let data = formData || {}
        console.log(formData)
        this.api.product.model.altStatus(this.businessId, data.state, data.reason).then(result => {
          if (result.data.success === true) {
            this.loadingSubmit.submit = false
            this.dialog.audit.visible = false
            this.$message.success('操作成功')
            this.getList()
          } else {
            this.$message.error(result.data.message)
            this.loadingSubmit.submit = false
          }
          resolve(result.data.data)
        }).catch(e => {
          reject(e)
        }).finally(() => {
          this.loadingSubmit.submit = false
        })
      })
    },
    editBeenOnOff (row, enabled) {
      this.api.product.model.altState(row.id, enabled).then(result => {
        if (result.data.success === true) {
          this.loading.list = false
          this.$message.success('操作成功')
          this.getList()
        } else {
          this.$message.error(result.data.message)
        }
      }).catch(e => {
        this.loading.list = false
      })
    },
    deleteBusiness (val) {
      this.api.product.model.delModel(val).then(result => {
        if (result.data.success === true) {
          this.loading.submit = false
          this.$message.success('操作成功')
          this.getList()
        } else {
          this.$message.error(result.data.message)
        }
      }).catch(e => {
        this.loading.submit = false
      })
    }
  }
}
</script>

<style>

</style>
